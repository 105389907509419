<template>
  <v-container>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title class="text-h4 text--primary">Контакты</v-card-title>
          <v-card-title>Генеральный директор:</v-card-title>
          <v-card-text>Седелев Юрий Анатолиевич</v-card-text>
          <v-card-title>Почтовый / юридический адрес:</v-card-title>
          <v-card-text>
            <p>617763, Пермский край, г. Чайковский,</p>
            <p>Приморский бульвар, 32</p>
          </v-card-text>
          <v-card-text>
            <v-btn href="mailto:info@introscan.ru">
              <v-icon left>mdi-email</v-icon>
              info@introscan.ru
            </v-btn>
          </v-card-text>
          <v-card-text>
            <v-btn href="tel:83424134595">
              <v-icon left>mdi-phone</v-icon>
              8 (34241) 3-45-95
            </v-btn>
          </v-card-text>
          <v-card-text>
            <v-btn href="../assets/docs/org.pdf" color="orange">
              <v-icon left>mdi-domain</v-icon>
              Карта организации
            </v-btn>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col>
        <v-card height="100%">
          <v-card-title></v-card-title>
          <v-card-text>
            <yandex-map
              zoom="17"
              style="width: 100%; max-width: 100%; height:450px"
              :coords="coords"
            >
              <ymap-marker
                marker-type="placemark"
                :coords="coords"
                marker-id="1"
                :icon="{
                  color: 'darkOrange',
                  glyph: 'science',
                }"
                hint-content="AO 'ИнтроСкан Технолоджи'"
              >
              </ymap-marker>
            </yandex-map>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { yandexMap, ymapMarker } from 'vue-yandex-maps';

export default {
  components: { yandexMap, ymapMarker },
  data() {
    return {
      coords: [56.777888, 54.139279],
    };
  },
};
</script>
